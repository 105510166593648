<template>
    <PageLayout>
        <template>
            <div class="d-flex justify-space-between mb-10">
                <div class="d-flex align-center flex-grow-1">
                    <h1>
                        <template v-if="currentFilter">
                            {{ currentFilter.name }}
                        </template>
                        <template v-else>
                            {{ $t('documents') }}
                        </template>
                    </h1>
                    <TableSettings
                        :fields="tableColumns"
                        :entityName="entityName"

                        @column-select="onColumnSelect"
                    />
                </div>
                <div class="text-right">
                    <Btn large :disabled="userBlock" :to="{name: 'document.create'}" color="secondary">
                        {{ $t('create') }}
                    </Btn>
                </div>
            </div>

            <div class="mobile-filter mb-5" v-if="$vuetify.breakpoint.smAndDown">
                <v-icon
                    class="mobile-filter__btn mr-2"
                    :disabled="userBlock"
                    color="secondary"
                    @click="filter_show === true ? filter_show = false : filter_show = true"
                    :title="$t['filter_add']"
                >mdi-filter-plus</v-icon>

                <FilterMobileLinks
                    :links="[
                        ...Additional.map((item) => {
                            return {
                                ...item
                            }
                        }),

                        ...adminItems.map((item) => {
                            return {
                                ...item,
                                title: item.name
                            }
                        }),

                        ...system_filters.map((item) => {
                            return {
                                ...item,
                                title: item.name
                            }
                        }),

                        ...userFilters.map((item) => {
                            return {
                                ...item,
                                title: item.name
                            }
                        }),
                    ]"
                />
            </div>

            <FilterComponent
                class="mb-10"
                v-if="filter_show"
                :filterData="filterData"
                :currentFilter="currentFilter"
                :value="filterValues"
                :loading="loading"
                :invalid="invalid"
                entity="document"
                @input="filterValues = $event"
                @submit="submitForm"
                @filterSaved="filterSaved"
                @filterDeleted="filterDeleted"
            />

            <template v-if="$vuetify.breakpoint.smAndDown">
                <ListItem
                    v-for="item in documents"
                    :item="item"
                    :key="item.id"
                    :columns="tableColumns"
                    :userBlock="userBlock"

                    @deleteItem="deleteDocument(item)"
                >


                    <template v-slot:files="{ item }">
                        <div v-for="(item2, index) in  item.files"  :key="index">
                            <div :class="index > 0 ? 'mt-5': ''" :style="'color:'+ item.document_flow_status.color+'!important'">
                                <v-btn text plain :to="'/document/' + item.uuid + '/show'"
                                       class="cursor-pointer font_weight_500 button_link"
                                       :style="'color:' + item.document_flow_status.color +'!important'"
                                >
                                    {{ item2.data.file_name}}
                                </v-btn>
                            </div>
                            <div>
                                {{item2.document_flow_type.name}}
                            </div>
                        </div>
                    </template>

                    <template v-slot:document_status_id="{ item }">
                        <div>
                            {{
                                documentStatuses.find((status) => {
                                    return status.id === item.document_flow_status_id
                                }).name
                            }}
                        </div>
                    </template>



                    <template v-slot:id="{ item }">
                        <v-btn text plain :to="'/document/' + item.uuid + '/show'" class="cursor-pointer font_weight_500 button_link ">
                            {{ item.id }}
                        </v-btn>
                    </template>



                    <template v-slot:created_at="{ item }">
                        <div>{{ $moment(item.created_at).format('DD.MM.YYYY') }}</div>
                    </template>




                    <template v-slot:author_id="{ item }">
                        <div>
                            <v-list subheader two-line class="background_none py-0">
                                <v-list-item class="px-0 py-0 my-0 min_height_auto">
                                    <v-list-item-avatar size="36" class="my-0 py-0" :class="{'border_blue': item.author.photo === '/img/avatar.png'}">
                                        <img v-if="item.author.photo !== '/img/avatar.png'" :src="item.author.photo" :alt="item.author.name">
                                        <v-icon v-else color="primary" size="22">
                                            ${{ 'settingsIcon' }}
                                        </v-icon>
                                    </v-list-item-avatar>
                                    <v-list-item-content class="py-0 title_subtitle">
                                        <v-list-item-title v-text="item.author.name"></v-list-item-title>
                                        <v-list-item-subtitle v-text="formatPhoneNumber(item.author.phone)"></v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </div>
                    </template>


                </ListItem>
            </template>
            <template v-else>
                <ResizableTable
                    class="main-data-table elevation-0 uveds-table"
                    :rows="documents"
                    :columns="tableColumns"
                    :sort-by="options.sortBy[0]"
                    :sort-dir="options.sortDesc[0]"

                    @sort="sortItems"
                    @columnMoved="onColumnsMoved"
                    @columnResized="onColumnResized"
                >

                    <template v-slot:id="{ item }">
                        <v-btn text plain :to="'/document/' + item.uuid + '/show'" class="cursor-pointer font_weight_500 button_link ">
                            {{ item.id }}
                        </v-btn>
                    </template>



                    <template v-slot:created_at="{ item }">
                        <div>{{ $moment(item.created_at).format('DD.MM.YYYY') }}</div>
                    </template>
                    <template v-slot:document_status_id="{ item }">
                        <div>
                            {{
                                documentStatuses.find((status) => {
                                    return status.id === item.document_flow_status_id
                                }).name
                            }}
                        </div>
                    </template>


                    <template v-slot:files="{ item }">
                        <div v-for="(item2, index) in  item.files"  :key="index">
                            <div :class="index > 0 ? 'mt-5': ''" :style="'color:' + item.document_flow_status.color +'!important'">
                                <v-btn text plain :to="'/document/' + item.uuid + '/show'"
                                       class="cursor-pointer font_weight_500 button_link"
                                       :style="'color:' + item.document_flow_status.color +'!important'"
                                >
                                    {{ item2.data.file_name}}
                                </v-btn>
                            </div>
                            <div>
                                {{item2.document_flow_type.name}}
                            </div>

                        </div>
                    </template>

                    <template v-slot:author_id="{ item }">
                        <div>
                            <v-list subheader two-line class="background_none py-0">
                                <v-list-item class="px-0 py-0 my-0 min_height_auto">
                                    <v-list-item-avatar size="36" class="my-0 py-0" :class="{'border_blue': item.author.photo === '/img/avatar.png'}">
                                        <img v-if="item.author.photo !== '/img/avatar.png'" :src="item.author.photo" :alt="item.author.name">
                                        <v-icon v-else color="primary" size="22">
                                            ${{ 'settingsIcon' }}
                                        </v-icon>
                                    </v-list-item-avatar>
                                    <v-list-item-content class="py-0 title_subtitle">
                                        <v-list-item-title v-text="item.author.name"></v-list-item-title>
                                        <v-list-item-subtitle v-text="formatPhoneNumber(item.author.phone)"></v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </div>
                    </template>

                    <template v-slot:recipients="{ item }">
                        <div v-if="item.recipients && item.recipients.length === 1 && item.recipients[0] && item.recipients[0].name">
                            <v-list subheader two-line class="background_none py-0">
                                <v-list-item class="px-0 py-0 my-0 min_height_auto">
                                    <v-list-item-avatar  size="36" class="my-0 py-0 cursor-pointer" >
                                        <img v-if="item.recipients[0].photo !== '/img/avatar.png'" :src="item.recipients[0].photo" :alt="item.recipients[0].name">
                                        <v-icon v-else color="primary" size="22">
                                            ${{'settingsIcon'}}
                                        </v-icon>
                                    </v-list-item-avatar>
                                    <v-list-item-content class="py-0 title_subtitle">
                                        <v-list-item-title v-text="item.recipients[0].name" ></v-list-item-title>
                                        <v-list-item-subtitle v-text="formatPhoneNumber(item.recipients[0].phone)"></v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </div>

                        <div v-if="item.recipients && item.recipients.length > 1">
                            <div class="avatars cursor-pointer">
                                <span class="avatar" v-for="(item, index) in item.recipients"  :key="index">
                                      <v-avatar size="36" >
                                          <img v-if="item.photo !== '/img/avatar.png'" :title="item.name" class="" :src="item.photo" :alt="item.name">
                                          <img v-else :title="item.name" class="avatar_border avatar_border_prime" src="/img/avatar.svg" :alt="item.name">
                                      </v-avatar>
                                </span>
                            </div>
                        </div>
                    </template>

                    <template v-slot:author="{ item }">
                        <div v-if="item.author && item.auditors.name">
                            <v-list subheader two-line class="background_none py-0">
                                <v-list-item class="px-0 py-0 my-0 min_height_auto">
                                    <v-list-item-avatar  size="36" class="my-0 py-0 cursor-pointer" >
                                        <img v-if="item.author.photo !== '/img/avatar.png'" :src="item.author.photo" :alt="item.author.name">
                                        <v-icon v-else color="primary" size="22">
                                            ${{'settingsIcon'}}
                                        </v-icon>
                                    </v-list-item-avatar>
                                    <v-list-item-content class="py-0 title_subtitle">
                                        <v-list-item-title v-text="item.auditors.name" ></v-list-item-title>
                                        <v-list-item-subtitle v-text="formatPhoneNumber(item.auditors.phone)"></v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </div>

                    </template>

                </ResizableTable>
            </template>

            <div class="main-pagination d-flex flex-row justify-end">
                <SelectInput
                    class="main-pagination__per-page"
                    id="pagination-variants"
                    :value="options.itemsPerPage"
                    :items="perPageItems"
                    :label="$t('items_per_page')"
                    :disabled="loading"
                    @input="onPerPageChange($event)"
                    hide-label
                ></SelectInput>
                <v-pagination v-model="page" :total-visible="totalVisiblePag" class="main-pagination__pagination pagination_table" :length="pageCount" :disabled="loading" @input="onPageChange"></v-pagination>
            </div>
        </template>

        <template v-slot:side>
            <v-icon v-if="$vuetify.breakpoint.mdAndUp" class="mt-2 mb-5" :disabled="userBlock" color="secondary" @click="filter_show===true ? filter_show=false : filter_show=true" :title="$t['filter_add']">mdi-filter-plus</v-icon>

            <v-list :disabled="userBlock" class="filter_left" v-if="Additional.length > 0 && $vuetify.breakpoint.mdAndUp">
                <v-list-group :ripple="false" v-model="group1" class="px-0">
                    <template v-slot:activator class="px-0">
                        <v-list-item-title :ripple="false" class="main_filter">{{ $t('system_filters') }}</v-list-item-title>
                    </template>
                    <v-list-item :ripple="false" v-for="(item, i) in Additional" :key="i" :to="item.url" :class="{'active-item': isSameRouteWithQueryParams($route, item.url)}">
                        <v-list-item-title v-if="item.can" v-text="item.title" class="cursor-pointer"></v-list-item-title>
                        <v-list-item-icon v-if="item.can" v-text="item.count" class="filter_count"></v-list-item-icon>
                    </v-list-item>
                </v-list-group>
            </v-list>

        </template>
    </PageLayout>
</template>

<script>
import { mapGetters } from "vuex"
import FilterView from "@/plugins/mixins/FilterView";
import PageLayout from "@/components/Leentech/PageLayout.vue";
import Btn from "@/components/Form/Btn.vue";
import SelectInput from "@/components/Form/SelectInput.vue";
import ResourceView from "@/plugins/mixins/ResourceView";
import NavMixin from "@/plugins/mixins/Nav";
import ListItem from "@/components/Document/DocumentItem.vue";
import FilterMobileLinks from "@/components/FilterMobileLinks.vue";

export default {
    name: "Documents",
    mixins: [
        FilterView,
        ResourceView,
        NavMixin
    ],
    components: {
        FilterMobileLinks,
        ListItem,
        Btn,
        PageLayout,
        SelectInput
    },
    data() {
        return {
            entityName: 'document',
            invalid: false,
            options: {
                ...FilterView.data().options,
                sortBy: ['id']
            },
            fab: false,
            documents: [],
            sortBy: "created_at",
            sortDir: false,
            page: 1,
            pageCount: 0,
            totalVisiblePag: 7,
            loading: false,
            Additional: [
                {
                    'title': this.$t('my_documents'),
                    'count': '-',
                    'action': 'all',
                    'can': 1,
                    'url': {
                        path: '/document',
                    }
                },
                {
                    'title': this.$t('inbox'),
                    'count': '-',
                    'action': 'inbox',
                    'can': 1,
                    'url': {
                        path: '/document',
                        query: {
                            filter: {
                                inbox: {
                                    custom: '1'
                                }
                            }
                        }
                    }
                },
                {
                    'title': this.$t('outbox'),
                    'count': '-',
                    'action': 'outbox',
                    'can': 1,
                    'url': {
                        path: '/document',
                        query: {
                            filter: {
                                outbox: {
                                    custom: '1'
                                }
                            }
                        }
                    }
                },
                {
                    'title': this.$t('favorite'),
                    'count': '-',
                    'action': 'favorite',
                    'can': 1,
                    'url': {
                        path: '/document',
                        query: {
                            filter: {
                                favorite: {
                                    custom: '1'
                                }
                            }
                        }
                    }
                },
            ],
            group1: true,
            userFiltersGroup: true,
            filter_show: false,
            adminItems: [],
            system_filters: [],
            documentStatuses: [],
        };
    },
    computed: {
        ...mapGetters(['lang', 'itemsPerPage', 'perPageItems', 'userBlock']),

    },
    mounted() {
        this.init();
    },

    methods: {
        async init() {
            await this.getDocumentStatuses();
            await this.getItems();
            await this.getFilters();
            await this.getDocumentCounts();
        },
        sortItems(column) {
            if (this.$route.query.sortBy === column) {
                let sortDir = this.$route.query.sortDir === 'true';

                this.$router.push({
                    query: {
                        ...this.$route.query,
                        sortDir: sortDir ? 'false' : 'true'
                    }
                });
            } else {
                this.$router.push({
                    query: {
                        ...this.$route.query,
                        sortBy: column,
                        sortDir: false
                    }
                });
            }
            // Здесь вы можете отсортировать элементы items
            // в соответствии с выбранным столбцом и порядком сортировки (asc/desc)
        },
        showDocument(item) {
            return {
                name: 'document.show',
                params: {
                    id: item.uuid
                }
            }
        },
        editDocument(item) {
            this.$router.push({
                name: 'document.edit',
                params: {
                    id: item.uuid
                }
            })
        },
        async deleteDocument(item) {
            if (confirm(this.$t('delete_document'))) {
                var _this = this
                this.loading = true;
                await this.$http
                    .delete(`admin/document/${item.id}`)
                    .then(res => {
                        this.$toastr.success(this.$t('document_has_been_deleted'))
                        this.getTasks()
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('document_has_not_been_deleted'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.loading = false
                    })
            }
        },
        getItems() {
            this.getDocuments()
        },
        async getDocuments(type) {
            this.loading = true;
            const {
                sortBy,
                sortDesc,
                page,
                itemsPerPage
            } = this.options;
            let params = {};

            if (sortBy[0] !== undefined) {
                params.sortBy = sortBy[0];
            }

            params.sortDir = 'asc';
            if(sortDesc) {
                params.sortDir = sortDesc[0] ? 'asc' : 'desc';
            }

            if (page !== undefined) {
                if (type === 'search') {
                    params.page = 1
                } else {
                    params.page = page
                }
            }

            if (itemsPerPage !== undefined) {
                params.perPage = itemsPerPage;
            }

            if (this.filterValues) {
                params.fltr = this.filterValues;
            }


            await this.$http
                .get("admin/document_flow_author", {
                    params: params,
                })
                .then(res => {
                    this.filterData = res.body.filter
                    this.documents = res.body.data
                    this.page = res.body.meta.current_page
                    this.pageCount = res.body.meta.last_page
                })
                .catch(err => {
                    this.documents = []
                    this.$toastr.error(this.$t('failed_to_get_list_documents'))
                })
                .finally(end => {
                    this.loading = false
                })
        },
        async getDocumentStatuses() {
            var _this = this;
            this.loading = true;

            let params = {};
            if (this.language) {
                params.language = this.language
            }
            await this.$http
                .get(`admin/document_flow_status`, {
                    params: params,
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.documentStatuses = res.body.data
                })
                .catch(err => {
                    this.documentStatuses = []
                    this.$toastr.error(this.$t('failed_to_get_document_flow_statuses'))
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })
        },
        async getDocumentCounts() {
            let params = {};
            await this.$http
                .get(`admin/document_flow_author_count`, {
                    params: params,
                })
                .then(res => {
                    if(res && res.data && res.data.data){
                        Object.keys(res.data.data).forEach(key => {
                            const actionItem = this.Additional.find(item => item.action === key);
                            const actionItem2 = this.system_filters.find(item => item.action === key);
                            if (actionItem) {
                                actionItem.count = res.data.data[key];
                            }
                            else if (actionItem2) {
                                actionItem2.count = res.data.data[key];
                            }
                        });
                    }
                })
                .catch(err => {
                    this.$toastr.error(this.$t('failed_to_get_list_counts'))
                });
        },



    }
}
</script>
